<template>
    <r-e-dialog
            title="修改密码"
            class="layer-contract-detail"
            :visible.sync="dialogVisible"
            show-footer
            top="10vh"
            width="400px"
            @click-submit="clickSubmit"
            @click-cancel="clickCancel"
            @close="clickCancel"
    >
        <el-form
                ref="formPublish"
                label-width="100px"
                size="small"
                :model="personnelData"
                :rules="rules"
        >
            <div class="flex">
                <!--<el-form-item label="登陆名" prop="username">
                    <el-input type="text" v-model="personnelData.username" placeholder="请输入登录名"></el-input>
                </el-form-item>-->
            </div>
            <div class="flex">
                <el-form-item label="旧密码" prop="oldPassword">
                    <el-input type="text" v-model="personnelData.oldPassword" placeholder="请输入旧密码"></el-input>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="新密码" prop="newPassword">
                    <el-input type="text" v-model="personnelData.newPassword" placeholder="请输入新密码"></el-input>
                </el-form-item>
            </div>
        </el-form>

    </r-e-dialog>
</template>

<script>
    import { userChangepassword } from "@/api/personnel-management";
    import { MessageSuccess,MessageError } from "@custom/message";
    export default {
        name: "dialog-user-changepassword",
        data(){
            return {
                dialogVisible: false,
                operationType: "",
                personnelData: { },
                rules:{
                    /*username: [ { required: true, message: '登陆名不能为空', trigger: 'blur'} ],*/
                    oldPassword: [ { required: true, message: '旧密码不能为空', trigger: 'blur'} ],
                    newPassword: [ { required: true, message: '新密码不能为空', trigger: 'blur'},],
                },
            };
        },
        methods:{
            openDialog(){
                this.dialogVisible=true;
            },
            clickSubmit(){
                let that=this;
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                        let {oldPassword,newPassword}=that.personnelData;
                        let paramData={oldPassword,newPassword};

                            //2022/03/31 王江毅 调用接口修改密码
                            userChangepassword(paramData).then(res=>{
                                console.log("update",res);
                                MessageSuccess('修改密码成功');
                                that.clickCancel();
                                loading.close();
                            }).catch(err=>{
                                loading.close();
                                MessageError('修改密码失败');
                            });
                    }
                });
            },
            clickCancel(){
                this.dialogVisible=false;
            }
        }
    }
</script>

<style scoped>

</style>